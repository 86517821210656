import email from "./email.svg";
import github from "./github.svg";
import kuhustle from "./kuhustle.png";
import linkedin from "./linkedin.svg";
import menu from "./menu.svg";
import upwork from "./upwork.svg";

const Icons = {
    email, github, kuhustle, linkedin, menu, upwork
}

export default Icons;